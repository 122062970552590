<template>
  <div class="w-100">
    <div class="w-100">
      <img src="../../assets/images/voucher_header.jpeg" class="w-100" />
    </div>
    <div class="container py-5 text-center text-white" v-if="state==='loading'">
      <div-loader />
    </div>
    <div class="container text-center" v-else-if="state==='error'">
      <error-div :message="stateMessage" />
    </div>
    <div class="container-fluid py-5" v-else>
      <div class="row">
        <div class="container">
          <div class="row">
            <div class="col-12 col-lg-6 offset-lg-3">
              <div class="col-12 voucherWrap pb-3 bg-white rounded-lg overflow-hidden shadow-lg">
                <div class="w-100 p-2 border-bottom mb-3">
                  <b>{{$t("buyFlippedGiftCard")}}</b>
                </div>

                <div class="form-row">
                  <div class="col-12 py-2">
                    <b>{{$t('selectCardTemplate')}}</b>
                  </div>
                  <div class="w-100 mb-2"></div>
                  <div class="col-12 col-md-6 mb-2 text-center" v-for="(card, index) in vouchers" :key="'cardKey'+index">
                    <div class="w-100 h-100 voucherCardTemplate"
                         @click="selectVoucher(card)" :class="(form.template===card.id) ? 'selected' : ''">
                      <b-img-lazy :src="card.img" />
                      <small class="d-block p-2">{{card.title}}
                        <span v-if="card.amount >0">
                          <b>({{$helper.convertToCurrency(card.amount)}})</b>
                        </span>
                      </small>
                    </div>
                  </div>
                  <div class="w-100 mb-3"></div>
                </div>

                <div class="form-row">
                  <b-form-group class="col-12" :label="$t('receiverEmail')">
                    <b-form-input v-model="form.receiverEmail" />
                  </b-form-group>
                  <b-form-group class="col-12">
                    <label>{{$t('receiversName')}} <em class="text-danger">*</em></label>
                    <b-form-input v-model="form.receiverName" />
                  </b-form-group>

                  <b-form-group class="col-12">
                    <label>{{$t('gifterName')}} <em class="text-danger">*</em></label>
                    <b-form-input v-model="form.gifterName" />
                  </b-form-group>

                  <div class="col-12 mb-2" v-if="voucher.amount === 0">
                    <div class="form-row">
                      <div class="col-auto mb-2"
                           v-for="(price,index) in prices" :key="'priceWrap'+index">
                        <div class="w-100 rounded priceSlot"
                             @click="setPrice(price)"
                             :class="(form.price===price) ? 'selected' : ''">
                          {{price}} &euro;
                        </div>
                      </div>
                      <div class="col-auto mb-2">
                        <b-input-group>
                          <b-form-input
                              :disabled="(voucher.amount > 0)"
                              :state="priceState"
                              @keyup="setCustomPrice"
                              v-model="customPrice"
                              autocomplete="off" class="priceSlotInput"></b-form-input>
                          <template #append>
                            <b-input-group-text>
                              &euro;
                            </b-input-group-text>
                          </template>
                        </b-input-group>
                      </div>
                    </div>
                  </div>


                  <b-form-group class="col-12" :label="$t('message')">
                    <b-form-textarea v-model="form.message" rows="4" style="resize: none" />
                  </b-form-group>

                  <div class="col-12 text-center" v-if="error">
                    <b-alert show variant="danger">
                      {{message}}
                    </b-alert>
                  </div>
                  <div class="col-12 text-center">
                    <b-button variant="yellow" :disabled="saving"
                              @click="buyGift">
                      <b-spinner v-if="saving" class="mr-3 mt-1 float-left" small></b-spinner>
                      {{$t('buyGift')}}
                    </b-button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DivLoader from "@/components/Template/DivLoader";
import ErrorDiv from "@/components/Template/ErrorDiv";
export default {
  name: "BuyVoucher",
  components: {ErrorDiv, DivLoader},
  mounted() {
    this.loadVouchers()
  },
  methods : {
    selectVoucher (card) {
      this.form.template=card.id;
      this.voucher = card
      if (card.amount > 0) {
        this.form.price = card.amount
        this.customPrice = card.amount
      } else {
        this.form.price = ''
        this.customPrice = ''
      }
    },
    loadVouchers () {
      this.state = 'loading'
      this.stateMessage = ''
      this.$http.get('front/getAvailableVouchers').then(response => {
        if(response.data.error===undefined) {
          this.state = "error";
          this.stateMessage = this.$t("errors.unExpectedError");
        } else if(response.data.error) {
          this.state = "error";
          this.stateMessage = response.data.message;
        } else {
          this.state = "success";
          this.vouchers = response.data.list;
          this.selectVoucher(this.vouchers[0])
        }
      }).catch(error => {
        this.state = "error";
        this.stateMessage = error.toString();
      })
    },
    buyGift() {
      this.saving = true;
      this.error = false;
      this.$http.post("front/buyGiftCard",this.form)
      .then(response=>{
        if(response.data.error===undefined) {
          this.saving = false;
          this.error = true;
          this.message = this.$t("errors.unableToBook");
        } else if(response.data.error) {
          if(response.data.errorCode===1) {
            this.saving = false;
            this.$bvModal.show("loginModal");
          } else {
            this.saving = false;
            this.error = true;
            this.message = response.data.message;
          }
        } else {
          this.form = {
            price : "",
            receiverEmail : "",
            receiverName : "",
            gifterName : "",
            message : "",
          };
          window.location = response.data.url;
        }
      }).catch(()=>{
        this.saving = false;
        this.error = true;
        this.message = this.$t("errors.unableToBook");
      });
    },
    setCustomPrice() {
      this.form.price = "";
      if(!/^[1-9]\d*$/.test(this.customPrice)) {
        this.priceState = false;
      } else {
        this.priceState = null;
        this.form.price = this.customPrice;
      }
    },
    setPrice(price) {
      this.form.price = price;
      this.customPrice = "";
      this.priceState = null;
    }
  },
  data() {
    return {
      state: 'loading',
      stateMessage: '',
      saving : false,
      message : "",
      error : false,
      prices : [
          "5","10","20","25","50","100","200","250","500"
      ],
      selectedPrice : "",
      customPrice : "",
      priceState : null,
      form : {
        template: '',
        price : "",
        receiverEmail : "",
        receiverName : "",
        gifterName : "",
        message : "",
      },
      vouchers: [],
      voucher: {}
    }
  }
}
</script>

<style scoped>
.voucherCardTemplate {
  cursor: pointer;
  border: 1px solid #EDEDED;
  border-radius: 5px;
  overflow: hidden;
}
</style>
